.d3-tip {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.4;
    padding: 20px;
    pointer-events: none !important;
    color: #203d5d;
    box-shadow: 0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2);
    background-color: #fff;
    border-radius: 4px;
}

/* Creates a small triangle extender for the tooltip */

.d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: #fff;
    position: absolute;
    pointer-events: none;
}

/* Northward tooltips */

.d3-tip.n:after {
    content: "▼";
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .d3-tip.n:after {
        display: none;
    }
}


/* Eastward tooltips */

.d3-tip.e:after {
    content: "◀";
    margin: -4px 0 0 0;
    top: 50%;
    left: -8px;
}

/* Southward tooltips */

.d3-tip.s:after {
    content: "▲";
    margin: 0 0 1px 0;
    top: -8px;
    left: 0;
    text-align: center;
}

/* Westward tooltips */

.d3-tip.w:after {
    content: "▶";
    margin: -4px 0 0 -1px;
    top: 50%;
    left: 100%;
}