@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    body {
        @apply text-gray-90;
    }
    .display-lg {
        @apply font-poppins text-[52px] font-medium;
    }
    .display-md {
        @apply font-poppins text-[44px] font-medium;
    }
    .display-sm {
        @apply font-poppins text-[36px] font-medium;
    }

    .headline-lg {
        @apply font-poppins text-[30px] font-medium;
    }
    .headline-sm {
        @apply font-poppins text-[26px] font-medium;
    }

    .title-lg {
        @apply font-poppins text-[22px] font-medium;
    }
    .title-sm {
        @apply font-poppins text-[16px] tracking-[0.15px] font-medium;
    }

    .label-lg {
        @apply font-roboto text-[14px] tracking-[0.4px] font-medium;
    }
    .label-sm {
        @apply font-roboto text-[12px] tracking-[0.5px] font-medium;
    }

    .body-lg {
        @apply font-roboto text-[16px] tracking-[0.15px];
    }
    .body-md {
        @apply font-roboto text-[14px] tracking-[0.25px];
    }
    .body-sm {
        @apply font-roboto text-[12px] tracking-[0.4px];
    }

    .link {
        @apply underline cursor-pointer text-accent;
        @apply hover:text-accent-dark active:text-accent;
        @apply disabled:text-gray-30 disabled:hover:text-gray-30 disabled:cursor-not-allowed;
    }
    .white-link {
        @apply underline cursor-pointer text-white;
        @apply active:text-white;
        @apply disabled:text-gray-30 disabled:hover:text-gray-30 disabled:cursor-not-allowed;
    }
    .elevation-1 {
        box-shadow: 0px 2px 4px rgba(26, 35, 47, 0.1);
    }
    .elevation-2 {
        box-shadow: 0px 4px 8px rgba(26, 35, 47, 0.1);
    }
    .scrollbar-none {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .scrollbar-none::-webkit-scrollbar {
        display: none;
    }
    .slider::-webkit-slider-thumb {
        width: 0.625em;
        height: 0.625em;
    }
    .slider::-webkit-slider-runnable-track {
        border-radius: 100vmax;
        background-color: cyan;
        height: 0.25em;
    }
    .slider::-moz-range-track {
        background-color: cyan;
    }
    .slider::-ms-track {
        background-color: cyan;
    }

    @keyframes tooltip-show {
        0% {
            opacity: 0;
            visibility: visible;
        }
        1% {
            opacity: 50;
        }
        2% {
            opacity: 100;
        }
        100% {
            opacity: 100;
        }
    }

    .group:hover .xgroup-hover\:visible {
        animation-name: tooltip-show;
        animation-delay: 0.4s;
        animation-duration: 20s;
        animation-iteration-count: 1;
    }

    @keyframes shake {
        0% {
            margin-left: 0rem;
        }
        25% {
            margin-left: 0.3rem;
        }
        75% {
            margin-left: -0.3rem;
        }
        100% {
            margin-left: 0rem;
        }
    }
}
